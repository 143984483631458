<template>
  <div>
    <title-bar :title="$t('navigations.orders')" />
    <div v-if="rows" class="custom-table">
      <advanced-filter
          :fields-array="filterFields"
          :action="showFilterModal"
          @clearAction="showFilterModal = false"
          @paramsChange="setAdvancedParams"
      />
      <table-actions
          :addBtnText="$t('messages.orderCreate')"
          :addBtnLink="$helper.getAddUrl('orders')"
          :actions="tableActions"
          @onSearchInput="inputChange"
          @perPageChange="perPageChange"
          @filterChange="showFilterModal = true"
          :defaultPerPage="perPage"
      />
      <table-lite
          :is-slot-mode="true"
          :is-loading="isLoading"
          :columns="columns"
          :rows="rows"
          :sortable="sortable"
          @do-search="setSort"
          @is-finished="isLoading = false"
      >
        <template v-slot:status="data">
          <b-badge :variant="$helper.getOrderStatusBadgeClass(data.value.status)">
            {{ $helper.getEnumTranslation("ORDER_STATUS", data.value.status, $i18n.locale) }}
          </b-badge>
        </template>
        <template v-slot:vendor="data">
          {{
            data.value.vendor.name
          }}
        </template>
        <template v-slot:user="data">
          {{
            data.value.user.givenName + " " + data.value.user.familyName
          }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }} {{ $helper.formatTime(data.value.createdAt) }}
        </template>
        <template v-slot:sentAt="data">
          <div v-if="data.value.sentAt">{{ $helper.formatDate(data.value.sentAt) }} {{ $helper.formatTime(data.value.sentAt) }}</div>
          <div v-else>-</div>
        </template>
        <template v-slot:elapsedDays="data">
          <div v-if="data.value.sentAt">{{ elapsedDays(data.value) }}</div>
          <div v-else>-</div>
        </template>
        <template v-slot:comments="data">
          <div v-if="data.value.comments">{{ data.value.comments }}</div>
          <div v-else>-</div>
        </template>
        <template v-slot:totalPrice="data">
          <div v-if="data.value.totalPrice">{{ $helper.priceFormat(data.value.totalPrice, 2) }}</div>
          <div v-else>-</div>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('orders', data.value.id)" />
            <delete-btn
                v-if="canDelete && data.value.status === 'NEW'"
                @pressDelete="deleteOrder(data.value['@id'])"
            />
          </div>
        </template>
      </table-lite>
      <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          @input="onChangePage"
      />
    </div>

  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import moment from "moment";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";

export default {
  name: "Orders",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
    AdvancedFilter
  },
  mixins: [ResourceUtils],
  created() {
    this.setPermissions()
    this.load()
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        status: ['NEW', 'PENDING', 'APPROVED_BY_MANAGER', 'SENT', 'WAITING_FOR_INVOICE', 'INVOICE_PAID', 'DONE'],
      };

      this.advancedParams.forEach(element => {
        if (element.value !== '' && element.value !== null) {
          params[element.name] = element.value
        }
      });

      params["order[" + this.sortable.order + "]"] = this.sortable.sort;

      if (this.search && this.search.length) {
        params.search = this.search;
      }

      this.$Orders.getCollection({params}, 'order_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    setPermissions() {
      if (this.$helper.userCapability('CREATE', 'ORDERS')) {
        this.tableActions.push('addBtn')
      }
      this.canCreate = this.$helper.userCapability('CREATE', 'ORDERS')
      this.canUpdate = this.$helper.userCapability('UPDATE', 'ORDERS')
      this.canDelete = this.$helper.userCapability('DELETE', 'ORDERS')
    },
    setAdvancedParams(data) {
      this.advancedParams = data
      this.load()
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    setSort(offset, limit, order, sort) {
      this.sortable.order = order;
      this.sortable.sort = sort;

      if (this.sortable.order === 'vendor') {
        this.sortable.order = 'vendor.name'
      }

      this.load();
    },
    elapsedDays(order) {
      if (!order.sentAt) {
        return 0
      }

      const currentDate = moment().toDate()
      const sentDate = moment(order.sentAt).toDate()

      const timeDifference = currentDate.getTime() - sentDate.getTime()
      return Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    },
    deleteOrder(url) {
      this.deleteByUrl(
          this.$Orders,
          url,
          this.$t("messages.orderDeleted"),
          null,
          this.load,
          this.error
      );
    },
  },
  data() {
    return {
      search: "",
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      showFilterModal: false,
      sortable: {
        order: "id",
        sort: "desc",
      },
      canUpdate: false,
      canDelete: false,
      canCreate: false,
      canView: false,
      tableActions: ['perPage','advancedFilter'],
      filterFields: [
        {
          label: 'forms.id',
          name: "id",
          value: null,
          type: "text",
        },
        {
          label: "forms.status",
          name: "status",
          value: null,
          type: "enum",
          enumName: "ORDER_STATUS"
        },
        {
          label: "forms.vendor",
          name: "vendor",
          value: null,
          type: "customSelect",
          resource: this.$Vendors,
          resourceType: "entity",
        },
      ],
      advancedParams: [],
      columns: [
        {
          label: this.$t("forms.orderId"),
          field: "id",
          sortable: true,
        },
        {
          label: this.$t("forms.createdBy"),
          field: "user",
          sortable: false,
        },
        {
          label: this.$t("forms.vendor"),
          field: "vendor",
          sortable: true,
        },
        {
          label: this.$t("forms.status"),
          field: "status",
          sortable: true,
        },
        {
          label: this.$t("forms.totalPrice"),
          field: "totalPrice",
          sortable: false,
        },
        {
          label: this.$t("forms.createdAt"),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t("forms.sentAt"),
          field: "sentAt",
          sortable: true,
        },
        {
          label: this.$t("forms.elapsedDays"),
          field: "elapsedDays",
          sortable: false,
          width: "80px",
        },
        {
          label: this.$t("forms.comments"),
          field: "comments",
          sortable: false,
          width: "250px",
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
      rows: null
    };
  },
};
</script>

